<template>
  <div class="patient">
   <h1>Patient: {{patient.lastName}}</h1>
   
   <form @submit.prevent="save">
      <br/>
      <button type="submit" class="small">Save</button>
    </form>
  </div>
</template>

<script>
import patientsService from '../../services/patients';

export default {
  name: 'Patient',
  props: ["id"],
  data: () => {
    return {
      office: null,
      patient: {}
    };
  },
  async mounted() {
    this.patient = await patientsService.getById(this.id);
  },
  methods: {
    save: async function() {
        await patientsService.save(this.patient);
        this.$toastr.s("Saved");
    }
  }
}
</script>