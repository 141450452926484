import api from './api';
import qs from 'qs';

export default {
    getList: async (params) => {
        const data = qs.stringify(params);
        const response = await api.get(`/patients?${data}`);
        return response.data;
    },

    getById: async (id) => {
        const response = await api.get(`/patients/${id}`);
        return response.data;
    },

    save: async (office) => {
        const response = await api.put(`/patients`, office);
        return response.data;
    },

    sendPasswordResetLink: async (id) => {
        const response = await api.get(`/patients/${id}/password/reset`);
        return response.data;
    },
}